/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"

import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import ShareButtons from "../components/share-buttons"

const ComeVotare = ({ data }) => (
  <Layout title="Come Si Vota">
    <SEO title="Come Si Vota" />
    <Container sx={{ maxWidth: 680, p: [4, null], textAlign: `justify` }}>
      <div sx={{ mb: 4 }}>
        <Img
          alt="Come si vota 1 - Lorenzoni Presidente"
          fluid={data.figure01.childImageSharp.fluid}
        />
      </div>
      <div sx={{ my: 4 }}>
        <Img
          alt="Come si vota 2 - Lorenzoni Presidente"
          fluid={data.figure02.childImageSharp.fluid}
        />
      </div>
      <div sx={{ my: 4 }}>
        <Img
          alt="Come si vota 3 - Lorenzoni Presidente"
          fluid={data.figure03.childImageSharp.fluid}
        />
      </div>
      <div sx={{ my: 4 }}>
        <Img
          alt="Come si vota 4 - Lorenzoni Presidente"
          fluid={data.figure04.childImageSharp.fluid}
        />
      </div>

      <ShareButtons
        title="Come Si Vota"
        url="https://www.arturolorenzoni.it/voto/"
      />
    </Container>
  </Layout>
)

export default ComeVotare

export const data = graphql`
  query {
    figure01: file(relativePath: { eq: "come-votare/comeVotare-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    figure02: file(relativePath: { eq: "come-votare/comeVotare-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    figure03: file(relativePath: { eq: "come-votare/comeVotare-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    figure04: file(relativePath: { eq: "come-votare/comeVotare-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
